import { SUPABASE_KEYS } from '@/config/supabase-keys';
import { CompanyMembers, User } from '@/types';
import { SendInvite, UpdateInvitedUser } from '@/types';
import { supabase } from '@/utils/supabase/client';
import { UserResponse } from '@supabase/supabase-js';

export interface UserResponseType {
  data: User[];
  pageCount: number;
}

const PAGE_SIZE = 5;

/**
 * Fetches user profiles with pagination and search capabilities
 */
const getProfile = async (id: string, searchQuery?: string, pageIndex?: number): Promise<UserResponseType> => {
  try {
    const range = pageIndex ? pageIndex - 1 : 0;
    const offset = range * PAGE_SIZE;
    
    let query = supabase
      .from(SUPABASE_KEYS.USERS)
      .select('*', { count: 'exact' })
      .neq('id', id)
      .order('email', { ascending: true });

    if (offset) {
      query = query.range(offset, offset + PAGE_SIZE + 1);
    }
    
    if (searchQuery) {
      // TODO: CAN'T SEARCH MEMBERS BY ROLE BECAUSE ROLE IS TYPE ROLE AND IT IS NOT SEARCHABLE BY ILIKE
      query = query.or(`name.ilike.%${searchQuery}%,firstname.ilike.%${searchQuery}%,lastname.ilike.%${searchQuery}%`);
    }

    const { data, count, error } = await query.limit(PAGE_SIZE);

    if (error) {
      throw new Error(`Failed to fetch profiles: ${error.message}`);
    }

    return {
      data: data ?? [],
      pageCount: Math.ceil((count ?? 0) / PAGE_SIZE),
    };
  } catch (error) {
    console.error('Error in getProfile:', error);
    throw error;
  }
};

/**
 * Authentication related functions
 */
const getInvitedUser = async (): Promise<UserResponse> => {
  try {
    return await supabase.auth.getUser();
  } catch (error) {
    console.error('Error in getInvitedUser:', error);
    throw error;
  }
};

const getSession = async () => {
  try {
    const { data: session } = await supabase.auth.getSession();
    return session;
  } catch (error) {
    console.error('Error in getSession:', error);
    throw error;
  }
};

const getUserId = async (): Promise<string> => {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    
    if (!user) {
      throw new Error('No authenticated user found');
    }
    
    return user.id;
  } catch (error) {
    console.error('Error in getUserId:', error);
    throw error;
  }
};

/**
 * User data retrieval functions
 */
const getLoggedUser = async (id: string): Promise<User | null> => {
  try {
    const { data, error } = await supabase
      .from(SUPABASE_KEYS.USERS)
      .select('*')
      .eq('id', id)
      .single();
    
    if (error) {
      throw new Error(`Failed to get logged user: ${error.message}`);
    }
    
    return data;
  } catch (error) {
    console.error('Error in getLoggedUser:', error);
    throw error;
  }
};

const getInvitedMember = async (id: string): Promise<User> => {
  try {
    const { data, error } = await supabase
      .from(SUPABASE_KEYS.USERS)
      .select('*')
      .eq('id', id)
      .single();
    
    if (error) {
      throw new Error(`Failed to get invited member: ${error.message}`);
    }
    
    if (!data) {
      throw new Error(`No member found with ID: ${id}`);
    }
    
    return data;
  } catch (error) {
    console.error('Error in getInvitedMember:', error);
    throw error;
  }
};

const getCompanyMembers = async (id: string, searchQuery?: string): Promise<CompanyMembers[]> => {
  try {
    let query = supabase
      .from(SUPABASE_KEYS.USERS)
      .select('*')
      .eq('company', id)
      .order('email', { ascending: true });

    if (searchQuery) {
      query = query.or(`name.ilike.%${searchQuery}%,role.ilike.%${searchQuery}%`);
    }

    const { data, error } = await query;
    
    if (error) {
      throw new Error(`Failed to get company members: ${error.message}`);
    }
    
    return data || [];
  } catch (error) {
    console.error('Error in getCompanyMembers:', error);
    throw error;
  }
};

/**
 * User data modification functions
 */
const setMemberRole = async (role: string, id: string): Promise<any> => {
  try {
    const { data, error } = await supabase
      .from(SUPABASE_KEYS.USERS)
      .update({ role })
      .eq('id', id)
      .select();
    
    if (error) {
      throw new Error(`Failed to set member role: ${error.message}`);
    }
    
    return data;
  } catch (error) {
    console.error('Error in setMemberRole:', error);
    throw error;
  }
};

const updateInvitedUser = async ({ id, company, firstname, lastname }: UpdateInvitedUser): Promise<void> => {
  try {
    const name = `${firstname} ${lastname}`;
    const { error } = await supabase
      .from(SUPABASE_KEYS.USERS)
      .update({ name, company, firstname, lastname })
      .eq('id', id);
    
    if (error) {
      throw new Error(`Failed to update invited user: ${error.message}`);
    }
  } catch (error) {
    console.error('Error in updateInvitedUser:', error);
    throw error;
  }
};

/**
 * API-based functions
 */
const sendInvite = async ({ email, firstname, lastname, company, role, redirect }: SendInvite): Promise<void> => {
  try {
    const response = await fetch('/api/auth/invite', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, firstname, lastname, company, role, redirect }),
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(`Failed to send invite: ${errorData?.message || response.statusText}`);
    }
  } catch (error) {
    console.error('Error in sendInvite:', error);
    throw error;
  }
};

const setPassword = async (password: string, id: string, company: string): Promise<void> => {
  try {
    const response = await fetch('/api/auth/set-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, password, company }),
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(`Failed to set password: ${errorData?.message || response.statusText}`);
    }
  } catch (error) {
    console.error('Error in setPassword:', error);
    throw error;
  }
};

const deleteMember = async (id: string): Promise<void> => {
  try {
    const response = await fetch('/api/auth/delete-member', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      throw new Error(`Failed to delete member: ${errorData?.message || response.statusText}`);
    }
  } catch (error) {
    console.error('Error in deleteMember:', error);
    throw error;
  }
};

export {
  getProfile,
  getInvitedUser,
  getSession,
  getUserId,
  getLoggedUser,
  getInvitedMember,
  getCompanyMembers,
  setMemberRole,
  updateInvitedUser,
  sendInvite,
  setPassword,
  deleteMember,
};